/* eslint-disable camelcase */
import { CUSTOMISE_SECTIONS as GIFT_SECTIONS } from 'config/gift-memberships-steps-defs';
import Icon from 'app/components/partials/icon';
import OrderPricing from 'app/components/partials/buy-memberships/order-pricing';
import PropTypes from 'prop-types';
import { CUSTOMISE_SECTIONS as PURCHASE_SECTIONS } from 'config/buy-memberships-steps';
import React from 'react';
import { setProductsSelectedAction } from 'app/ducks/buy-memberships';
import { useBuyMembership } from 'app/contexts/buy-membership-context';
import { EVENT_ADD_TO_CART, EVENT_REMOVE_FROM_CART, fireCartEvent, getUnitPrice, validateSelectedProducts } from 'app/utilities/buy-memberships';
import { useDispatch, useSelector } from 'react-redux';

const Customise = ({
    totalCosts,
    saveCosts,
    costsAfterDiscount,
    stepClickHandler,
    isGifting = false
}) => {
    const dispatch = useDispatch();
    const {
        currentStepIndex,
        passHolders,
        productsSelected,
        renewDiscountPct,
        renewPassId,
        hasRenewDiscount
    } = useSelector((state) => state.buyMemberships);

    // Gifting specific variations
    const customiseSections = isGifting ? GIFT_SECTIONS : PURCHASE_SECTIONS;
    const excludeProducts = isGifting ? ['Support person'] : [];

    const buyMembership = useBuyMembership();

    const products = buyMembership.products.filter((product) => !excludeProducts.includes(product.name));

    const normalProducts = products.filter((product) => !product.is_guest);
    const guestProducts = products.filter((product) => product.is_guest);

    const validationErrors = validateSelectedProducts(productsSelected);
    const hasValidationErrors = Object.keys(validationErrors).length > 0;

    const selectProductHandler = (productIndex, quantitiesToSelect, qtyChanged) => {
        const productToSelectData = products[productIndex];

        // Fire analytics event
        const event = qtyChanged > 0 ? EVENT_ADD_TO_CART : EVENT_REMOVE_FROM_CART;
        fireCartEvent(
            event,
            // convert to same format as the state in buyMmeberships.productsSelected
            [{
                ...productToSelectData,
                quantities: Math.abs(qtyChanged),
                product: productToSelectData.name,
            }],
            costsAfterDiscount,
            renewPassId,
            isGifting,
            buyMembership.selectedProductName,
        );

        dispatch(setProductsSelectedAction([{ ...productToSelectData, quantities: quantitiesToSelect }]));
    };

    const checkboxOnChangeHandler = (event, index) => {
        if (event.target.checked) return selectProductHandler(index, 1, 1);

        return selectProductHandler(index, 0, -1);
    };

    // Guest checkbox
    const isGuestCheckboxDisabled = (productCategory) => {
        // Allow adult guest and support person with any paying member
        const hasPayingProducts = productsSelected.some(({ product_category, is_guest }) => product_category !== 'cub' && !is_guest);
        if (hasPayingProducts) {
            return false;
        }

        // Specifically allow adult guest to be bought with any named passholder, even cubs
        if (productCategory === 'adult-guest') {
            return !productsSelected.some(({ is_guest }) => !is_guest);
        }

        return true;
    };

    // Named passholder inputs
    const checkInputDisabled = (isCub, mode) => {
        if (isCub) {
            // Disable add button if there's only one cub without any other paying passholders
            const hasCub = productsSelected.find(({ product_category }) => product_category === 'cub');
            if (hasCub && productsSelected.every(({ product_category, is_guest }) => product_category === 'cub' || is_guest)) {
                return mode === 'add';
            }
        }

        return false;
    };

    const renderInputOrCheckbox = (productCategory, isGuest, isCub, productIndex, quantities) => {
        return isGuest ? (
            (
                <div className="checkbox-wrapper">
                    <label>
                        <input
                            type="checkbox"
                            checked={quantities > 0}
                            onChange={(event) => checkboxOnChangeHandler(event, productIndex)}
                            disabled={isGuestCheckboxDisabled(productCategory)}
                        />
                        <span className="checkbox-button">
                            <Icon className="icon" name="check" />
                        </span>
                    </label>
                </div>
            )
        ) : (
            <div className="select-product-quantities-quantity-input">
                <button
                    className="change-quantity"
                    title="Remove ticket"
                    onClick={() => selectProductHandler(productIndex, quantities - 1, -1)}
                    disabled={quantities === 0 || checkInputDisabled(isCub, 'remove')}
                >−</button>
                <div className="quantity">{quantities}</div>
                <button
                    className="change-quantity"
                    title="Add ticket"
                    onClick={() => selectProductHandler(productIndex, quantities + 1, 1)}
                    disabled={checkInputDisabled(isCub, 'add')}
                >+</button>
            </div>
        );
    };

    const renderValidationError = (productCategory) => {
        const errorMsg = validationErrors[productCategory];
        if (errorMsg) {
            return (
                <tr>
                    <td colSpan="3" className="select-product-quantities-error help-text">{errorMsg}</td>
                </tr>
            );
        }

        return null;
    };

    const renderProducts = (products) => {
        return products.map(({
            name,
            description,
            price,
            product_index: productIndex,
            product_category: productCategory,
            is_guest: isGuest,
        }) => {
            const isProductSelected = productsSelected.find(({ product_index }) => product_index === productIndex);
            const productSelectQuantities = isProductSelected ? isProductSelected.quantities : 0;
            const isCub = productCategory === 'cub';

            return (
                <React.Fragment key={productIndex}>
                    <tr className="select-product-quantities-row">
                        <td className="select-product-quantities-name">
                            <div className="select-product-quantities-name-label">{name}</div>
                            <div className="select-product-quantities-name-description">{description}</div>
                        </td>
                        <td className="select-product-quantities-quantity">
                            {renderInputOrCheckbox(productCategory, isGuest, isCub, productIndex, productSelectQuantities)}
                        </td>
                        <td className="select-product-quantities-price">
                            {hasRenewDiscount &&
                                <span className="select-product-quantities-price-old">${price}</span>
                            }
                            <strong className={hasRenewDiscount ? 'select-product-quantities-price-final' : ''}>${getUnitPrice(price, renewDiscountPct)}</strong>
                        </td>
                    </tr>
                    {renderValidationError(productCategory, productIndex)}
                </React.Fragment>
            );
        });
    };

    return (
        <div className="inner">
            <div className="constrain-width medium no-pad">
                {customiseSections.map(({ title, subtitle, type }, index) => {
                    const isGuestSection = type === 'guest';
                    const productsDisplay = isGuestSection ? guestProducts : normalProducts;

                    return (
                        <section className="section" key={index}>
                            <h4 className="title with-tail">{title}</h4>
                            <p className="subtitle">{subtitle}</p>

                            <table className="select-product-quantities">
                                <tbody>{renderProducts(productsDisplay)}</tbody>
                            </table>
                        </section>
                    );
                })}

                <OrderPricing
                    totalCosts={totalCosts}
                    saveCosts={saveCosts}
                    costsAfterDiscount={costsAfterDiscount}
                    displayProductesSelectedList={false}
                />

                { passHolders && passHolders.length > 15 && <p className="error-message large">If you wish to purchase memberships for a large group, please contact us at <a href="mailto:annual.passes@aucklandzoo.co.nz">annual.passes@aucklandzoo.co.nz</a>.</p> }

                <section className="section button-group">
                    <button
                        className="button"
                        disabled
                    >Back</button>
                    <button
                        className="button primary"
                        onClick={() => stepClickHandler(currentStepIndex + 1)}
                        disabled={hasValidationErrors}
                    >
                        Next
                    </button>
                </section>
            </div>
        </div>
    );
};

Customise.propTypes = {
    totalCosts: PropTypes.string.isRequired,
    saveCosts: PropTypes.string.isRequired,
    costsAfterDiscount: PropTypes.string.isRequired,
    stepClickHandler: PropTypes.func.isRequired,
    customiseSections: PropTypes.array,
    isGifting: PropTypes.bool
};

export default Customise;
